import { apiv1 } from "@/request/apiv1";
import qs from "qs";
// 获取每日数据报表的具体客户列表
export function GetStagePivotClientList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
      apiv1.get(`/v1/clients/logs/clients/page?${qs.stringify(params, {
          arrayFormat: "repeat"
      })}`)
          .then(res => {
              if (res.status === 200 || res.status === 206) {
                  resolve(res)
              }
          })
          .catch(err => {
              reject(err)
          });
  })
}

// 根据id获取具体的客户日志数据
export function GetClientLogsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/logs/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="客户名称">
            <el-input
              v-model="tableFilters.name"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="联系人">
            <el-input
              v-model="tableFilters.mainContactName"
              placeholder="请输入"
              clearable
              @change="onContactName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="联系人电话">
            <el-input
              v-model="tableFilters.mainContactPhone"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="部门(多选)">
              <el-cascader
                style="width: 100%"
                :options="_departmnetTreeOptions"
                v-model="tableFilters.departmentIds"
                collapse-tags
                :props="{
                  multiple: true,
                  emitPath: false,
                  label: 'name',

                  value: 'id',
                  children: 'subs',
                }"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="跟进状态">
              <BasicSelect
                v-model="tableFilters.followUpStatusId"
                :allOptions="followUpStatusOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="负责人(多选)">
              <BasicSelect
                multiple
                v-model="tableFilters.ownerIds"
                :allOptions="ownerOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户来源(多选)">
              <BasicSelect
                multiple
                v-model="tableFilters.sourceId"
                :allOptions="sourceOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户类型(多选)">
              <BasicSelect
                multiple
                v-model="tableFilters.typeId"
                :allOptions="typeOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户阶段(多选)">
              <BasicSelect
                multiple
                v-model="tableFilters.stageId"
                :allOptions="stageOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="国家">
              <BasicSelect
                v-model="tableFilters.country"
                :allOptions="countryOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="省份/州">
              <BasicSelect
                v-model="tableFilters.province"
                :allOptions="provinceOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="城市">
              <BasicSelect
                v-model="tableFilters.city"
                :allOptions="cityOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="地区">
              <BasicSelect
                v-model="tableFilters.region"
                :allOptions="regionOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="标签(多选)">
              <BasicSelect
                multiple
                v-model="tableFilters.tags"
                :allOptions="tagOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="是否重复">
              <BasicSelect
                v-model="tableFilters.repeated"
                :allOptions="repeatedOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="启/禁状态">
              <el-select
                v-model="tableFilters.disabled"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="启用" :value="false"> </el-option>
                <el-option label="禁用" :value="true"> </el-option>
                <el-option label="全部" :value="null"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="自动拉取">
              <el-select
                v-model="tableFilters.isFetched"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="是" :value="true"> </el-option>
                <el-option label="否" :value="false"> </el-option>
                <el-option label="全部" :value="null"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="开始日期">
              <el-date-picker
                style="width: 100%"
                v-model="tableFilters.startDate"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="结束日期">
              <el-date-picker
                style="width: 100%"
                v-model="tableFilters.endDate"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建人">
              <BasicSelect
                v-model="tableFilters.createdById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改人">
              <BasicSelect
                v-model="tableFilters.lastModifiedById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import clientForm from "@/views/client/mixins/clientForm";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
import stagePivotClientList_tableFilters from "@/views/statisticAnalysis/stagesPivot/mixins/stagePivotClientList_tableFilters";
export default {
  components: {
    BasicSelect,
  },
  mixins: [clientForm, stagePivotClientList_tableFilters],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {},
    };
  },
  computed: {
    userOptions() {
      return this.$store.getters.userOptions;
    },
    regionOptions() {
      return this.$store.getters.regionOptions;
    },
    cityOptions() {
      return this.$store.getters.cityOptions;
    },
    provinceOptions() {
      return this.$store.getters.provinceOptions;
    },
    countryOptions() {
      return this.$store.getters.countryOptions;
    },
    _departmnetTreeOptions() {
      return this.$store.getters.departmnetTreeOptions;
    },
  },
  created() {
    this.initFormSelection();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "StagePivotClientList") {
          if (Object.keys(this.$route.query).length > 0) {
            this.tableFilters = onClearFilter(this.tableFilters);
            let params =  JSON.parse(this.$route.query.filters)
            for (const key in params) {
              this.tableFilters[key] = params[key];
            }
          }
          this.onSearch();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
      this.$emit("confirm", this.tableFilters);
    },
    onResetFilter() {
      let params = onClearFilter(this.tableFilters);
      this.tableFilters = onClearFilter(this.tableFilters);
      if (typeof params.repeated !== "boolean") {
        delete params.repeated;
      }
      this.tableFilters.disabled = false;
      params.disabled = false;
      this.$emit("change", params);
      this.$emit("clear", params);
    },
    onContactName() {
      if (this.tableFilters.mainContactName === "*#excel#***0") {
        this.$store.commit("app/SetCodeToExport", true);
      }
      if (this.tableFilters.mainContactName === "*#excel#***1") {
        this.$store.commit("app/SetCodeToExport", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 15px;
  margin-bottom: 0;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>